@font-face {
    font-family: AmericaCaptain;
    src: url("/fonts/american-captain.otf") format("opentype"),
    url("/fonts/american-captain.ttf") format("truetype");
}

.acFont {
    font-family: AmericaCaptain, Roboto, serif;
}

#root,
body {
    overflow-x: hidden;
    min-height: 100vh;
    cursor: unset;
    position: relative;
    font-family: "Alegreya Sans SC", Roboto, serif;
    font-weight: 100;
}

@media (min-width: 768px) {
    #root,
    body {
        cursor: none;
    }
}

.tao {
    width: 100vw;
    opacity: 1;
    margin-top: 0;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
}

a {
    color: unset !important;
    cursor: none !important;
    text-decoration: none !important;
}

p {
    margin: 0;
}

@media (min-width: 768px) {
    p {
        /*text-indent : 2rem;*/
    }
}

.align-items-unset {
    align-items: unset !important;
}

.nice-1 {
    background: grey;
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
}

.nice-2 {
    background: grey;
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
}

.nice-3 {
    background: grey;
    position: absolute;
    width: 100%;
    height: 75%;
    bottom: 0;
}

.nice-4 {
    background: grey;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
}

.ReactModal__Overlay--after-open {
    position: absolute !important;
}

.modal-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.ReactGridGallery_tile-icon-bar, .ReactGridGallery_tile-bottom-bar,
.ReactGridGallery_custom-overlay, .ReactGridGallery_tile-overlay {
    z-index: 3;
}

.react-responsive-modal-modalCenter {
    cursor: default;
}

.strokeBlack {
    color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.ReactGridGallery_tile-bottom-bar div {
     cursor: none !important;
}
