.h_iframe-aparat_embed_frame {
  position: relative;
  width: 100%;
  height: 100%;
}
.h_iframe-aparat_embed_frame .ratio {
	display: block;
	width: 100%;
	height: auto;
}
.h_iframe-aparat_embed_frame iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  position: absolute;
  overflow: hidden;
}